var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CreateIndicateur, IndicateurToString } from "adwone-engine/index.bin";
import { CellValue } from "adwone-engine/types.bin";
import { ADWGrid, ePropertyEvent } from "adwone-lib/index";
import axios from "axios";
import { Client } from "hub-lib/client/client.bin";
import { lnk_ChangeRate } from "hub-lib/dto/client/lnk_ChangeRate.bin";
import { eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { clone, GetHashCode, hasOwnProperty, memoizeAsyncBase } from "hub-lib/tools.bin";
import { recurse } from "tools-lib";
import { selectItems } from "../../../../redux/gridSlice";
import { store } from "../../../../redux/store";
import { ConsoleDebug } from "../../../../utils/localstorage.bin";
import { GridBase } from "./GridBase.bin";
import { SanitizeFilter } from "hub-lib/models/external.bin";
var ComputeMemoized = memoizeAsyncBase(function (indicateurInstance, msg) { return __awaiter(void 0, void 0, void 0, function () { var res; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, indicateurInstance.Compute([msg])];
        case 1:
            res = _a.sent();
            return [2 /*return*/, res];
    }
}); }); });
var VertexGrid = /** @class */ (function (_super) {
    __extends(VertexGrid, _super);
    function VertexGrid() {
        var _this = _super.apply(this, arguments) || this;
        _this.getFormatedCell = function (col, rows) { return __awaiter(_this, void 0, void 0, function () {
            var time7491, ind, signature, time4668, cells, _i, rows_1, row, res, cellValue, _time4668, time9009, _time9009, _time7491;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        time7491 = new Date().getTime();
                        if (!col.baseColumn) return [3 /*break*/, 5];
                        ind = CreateIndicateur(col.baseColumn);
                        signature = IndicateurToString(ind);
                        time4668 = new Date().getTime();
                        cells = [];
                        _i = 0, rows_1 = rows;
                        _a.label = 1;
                    case 1:
                        if (!(_i < rows_1.length)) return [3 /*break*/, 4];
                        row = rows_1[_i];
                        return [4 /*yield*/, ComputeMemoized(ind, row.dataItem)];
                    case 2:
                        res = _a.sent();
                        cellValue = new CellValue();
                        cellValue.Value = res;
                        cellValue.IndicateurSignature = signature;
                        cellValue.Type = 'cell';
                        cells.push(cellValue);
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        _time4668 = new Date().getTime();
                        ConsoleDebug("[getFormatedCell] [compute] ".concat(_time4668 - time4668, "ms"));
                        time9009 = new Date().getTime();
                        _time9009 = new Date().getTime();
                        ConsoleDebug("[getFormatedCell] [formatCells] ".concat(_time9009 - time9009, "ms")); // cellsFormated.forEach((cell, i) => {
                        _a.label = 5;
                    case 5:
                        _time7491 = new Date().getTime();
                        ConsoleDebug("[getFormatedCell] Elapsed ".concat(_time7491 - time7491, "ms"));
                        return [2 /*return*/];
                }
            });
        }); };
        _this.elementsToSelect = [];
        return _this;
    }
    VertexGrid.prototype.isRowSelected = function (row) {
        var _this$elementsToSelec; // implémentation de base, peut etre réécrit
        if ((_this$elementsToSelec = this.elementsToSelect) !== null && _this$elementsToSelec !== void 0 && _this$elementsToSelec.includes(row["@rid"])) {
            this.elementsToSelect = this.elementsToSelect.filter(function (e) { return e != row["@rid"]; });
            return true;
        }
        return false;
    }; /**
         * Flush
         * @param rows
         */
    VertexGrid.prototype.endCompute = function (rows) {
        return __awaiter(this, void 0, void 0, function () { var selectedItems; return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!this.props.computeCellValues) return [3 /*break*/, 2];
                    console.log("{computeCellValues} enable");
                    return [4 /*yield*/, this.ComputeCellValues(rows)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    this.elementsToSelect = [];
                    selectedItems = [];
                    recurse(rows, "Children", function (e) { if (e.selected)
                        selectedItems.push(e); });
                    store.dispatch(selectItems(selectedItems));
                    return [2 /*return*/];
            }
        }); });
    };
    VertexGrid.prototype.rowToObjectAfter = function (object, row) { object["@rid"] = row.dataItem["@rid"]; };
    VertexGrid.prototype.filterColumns = function (value) { if (!RightManager.hasRight(lnk_ChangeRate.name, eRights.read))
        return value.filter(function (v) { var _v$baseColumn, _v$baseColumn$options; return !((_v$baseColumn = v.baseColumn) !== null && _v$baseColumn !== void 0 && (_v$baseColumn$options = _v$baseColumn.options) !== null && _v$baseColumn$options !== void 0 && _v$baseColumn$options['isPriceReturned']); }); return value; };
    VertexGrid.prototype.create = function (row) {
        return __awaiter(this, void 0, void 0, function () {
            var vertex, errors, error_1, _error$response, _error$response$data, _error$response$data$, _errorDesc$properties, errorDesc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        vertex = this.rowToObject(row);
                        errors = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        return [4 /*yield*/, this.validator(vertex, errors, row)];
                    case 2:
                        _a.sent();
                        if (!!errors.length) return [3 /*break*/, 6];
                        if (!!this.props.onInlineNew) return [3 /*break*/, 4];
                        return [4 /*yield*/, Client.createVertex(this.objectPrototype.name, vertex)];
                    case 3:
                        _a.sent(); //Notify(Trad("create_success"), "success")
                        this.Initialize();
                        return [3 /*break*/, 5];
                    case 4:
                        this.props.onInlineNew(vertex, row);
                        _a.label = 5;
                    case 5: return [2 /*return*/, true];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_1 = _a.sent();
                        errorDesc = error_1 === null || error_1 === void 0 ? void 0 : (_error$response = error_1.response) === null || _error$response === void 0 ? void 0 : (_error$response$data = _error$response.data) === null || _error$response$data === void 0 ? void 0 : (_error$response$data$ = _error$response$data.error) === null || _error$response$data$ === void 0 ? void 0 : _error$response$data$.data;
                        if (errorDesc !== null && errorDesc !== void 0 && (_errorDesc$properties = errorDesc.properties) !== null && _errorDesc$properties !== void 0 && _errorDesc$properties.length)
                            errorDesc.properties.forEach(function (p) { return errors.push(p); });
                        return [3 /*break*/, 8];
                    case 8:
                        if (errors !== null && errors !== void 0 && errors.length)
                            ConsoleDebug("Error on properties.", errors);
                        ADWGrid.onErrorProperties.emit(ePropertyEvent.error, errors);
                        return [2 /*return*/, false];
                }
            });
        });
    };
    VertexGrid.prototype.update = function (row) {
        return __awaiter(this, void 0, void 0, function () {
            var vertex, errors, _this$props$onUpdateS, _this$props, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        vertex = this.rowToObject(row);
                        errors = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 9, , 10]);
                        return [4 /*yield*/, this.validator(vertex, errors, row)];
                    case 2:
                        _a.sent();
                        if (!!errors.length) return [3 /*break*/, 8];
                        if (!!this.props.onInlineEdited) return [3 /*break*/, 4];
                        (_this$props$onUpdateS = (_this$props = this.props).onUpdateStarting) === null || _this$props$onUpdateS === void 0 ? void 0 : _this$props$onUpdateS.call(_this$props, vertex, row);
                        return [4 /*yield*/, Client.updateVertex(this.objectPrototype.name, vertex)];
                    case 3:
                        _a.sent(); // Notify(Trad("update_success"), "success")
                        this.Initialize();
                        return [3 /*break*/, 7];
                    case 4:
                        this.props.onInlineEdited(vertex, row);
                        return [4 /*yield*/, this.ComputeRow(row, row.dataItem)];
                    case 5:
                        _a.sent();
                        if (!this.props.computeCellValues) return [3 /*break*/, 7];
                        console.log("{computeCellValues} enable");
                        return [4 /*yield*/, this.ComputeCellValues([row])];
                    case 6:
                        _a.sent();
                        _a.label = 7;
                    case 7: return [2 /*return*/, true];
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        error_2 = _a.sent();
                        console.log("error", error_2);
                        return [3 /*break*/, 10];
                    case 10: return [2 /*return*/, false];
                }
            });
        });
    };
    VertexGrid.prototype.delete = function (rows) {
        return __awaiter(this, void 0, void 0, function () { var verteces, vertecesAlt; return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    verteces = rows.map(function (row) { return row.dataItem["@rid"]; });
                    vertecesAlt = rows.map(function (row) { return row.dataItem; });
                    if (!!this.props.onInlineDeleted) return [3 /*break*/, 2];
                    return [4 /*yield*/, Client.deleteVertex(this.objectPrototype.name, verteces).catch(function (e) { return console.error(e); })];
                case 1:
                    _a.sent();
                    this.Initialize();
                    return [3 /*break*/, 3];
                case 2:
                    this.props.onInlineDeleted(vertecesAlt, rows);
                    _a.label = 3;
                case 3: return [2 /*return*/, true];
            }
        }); });
    };
    return VertexGrid;
}(GridBase));
export { VertexGrid };
var source = null;
var sourceTotal = null;
var DataGrid = /** @class */ (function (_super) {
    __extends(DataGrid, _super);
    function DataGrid(props) {
        var _this = _super.call(this, props) || this;
        _this.cancelMode = void 0;
        _this.bodyParameters = void 0;
        _this.currentTable = void 0;
        _this.getTable = function (options) { return __awaiter(_this, void 0, void 0, function () { var _this$props$configura, _this$props$configura2, _this$props2, _agg$data, cancelToken, baseArgs, body, _body, agg; return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    cancelToken = options.cancelToken, baseArgs = options.baseArgs;
                    body = __assign(__assign({ type: "aggregate", document: this.props.objectPrototype.name, dimensions: [], dimensionsColumns: [], columns: (_this$props$configura = (_this$props$configura2 = this.props.configuration) === null || _this$props$configura2 === void 0 ? void 0 : _this$props$configura2.Columns) !== null && _this$props$configura !== void 0 ? _this$props$configura : [], filter: (_this$props2 = this.props) === null || _this$props2 === void 0 ? void 0 : _this$props2.vertexParams, referentialOnly: false, hideDetailsRows: false, hideDetailsData: true, groupingRows: false }, (baseArgs !== null && baseArgs !== void 0 ? baseArgs : {})), this.bodyParameters);
                    _body = clone(body);
                    return [4 /*yield*/, SanitizeFilter(_body.document, _body.filter)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, Client.aggregate(_body, cancelToken === null || cancelToken === void 0 ? void 0 : cancelToken.token)];
                case 2:
                    agg = _a.sent();
                    return [2 /*return*/, agg === null || agg === void 0 ? void 0 : (_agg$data = agg.data) === null || _agg$data === void 0 ? void 0 : _agg$data.table];
            }
        }); }); };
        _this.UpdateTotalRowsFromServer = function () { return __awaiter(_this, void 0, void 0, function () { var _sourceTotal, _this$currentTable, _table$Rows, time1543, currentViewMode, gridFilter, table, totalRow, preparedRowTotal, _time1543; return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    time1543 = new Date().getTime();
                    (_sourceTotal = sourceTotal) === null || _sourceTotal === void 0 ? void 0 : _sourceTotal.cancel("Cancel");
                    sourceTotal = axios.CancelToken.source();
                    currentViewMode = this.props.configuration.ViewMode;
                    if (currentViewMode !== "Table")
                        return [2 /*return*/, null];
                    gridFilter = store.getState().grid.gridFilter;
                    return [4 /*yield*/, this.getTable({ cancelToken: this.cancelMode && sourceTotal, baseArgs: { onlyTotal: true, headerFilters: gridFilter, Uuid: (_this$currentTable = this.currentTable) === null || _this$currentTable === void 0 ? void 0 : _this$currentTable.Uuid } })];
                case 1:
                    table = _a.sent();
                    totalRow = table === null || table === void 0 ? void 0 : (_table$Rows = table.Rows) === null || _table$Rows === void 0 ? void 0 : _table$Rows[0];
                    preparedRowTotal = DataGrid.prepareRow(totalRow);
                    _time1543 = new Date().getTime();
                    console.log("[UpdateTotalRowsFromServer] Elapsed ".concat(_time1543 - time1543, "ms"));
                    return [2 /*return*/, preparedRowTotal];
            }
        }); }); };
        _this.UpdateRowsFromServer = function () { return __awaiter(_this, void 0, void 0, function () {
            var _source, _table$Rows2, _table$Rows3, _table$Rows3$, _gridFilter$filters, table, totalRow, rows, preparedRowTotal, preparedRows, gridFilter;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (_source = source) === null || _source === void 0 ? void 0 : _source.cancel("Cancel");
                        source = axios.CancelToken.source();
                        return [4 /*yield*/, this.getTable({ cancelToken: this.cancelMode && source })];
                    case 1:
                        table = _a.sent();
                        this.currentTable = table;
                        totalRow = table === null || table === void 0 ? void 0 : (_table$Rows2 = table.Rows) === null || _table$Rows2 === void 0 ? void 0 : _table$Rows2[0];
                        rows = table === null || table === void 0 ? void 0 : (_table$Rows3 = table.Rows) === null || _table$Rows3 === void 0 ? void 0 : (_table$Rows3$ = _table$Rows3[0]) === null || _table$Rows3$ === void 0 ? void 0 : _table$Rows3$.Children;
                        preparedRowTotal = DataGrid.prepareRow(totalRow);
                        preparedRows = rows.map(DataGrid.prepareRow);
                        gridFilter = store.getState().grid.gridFilter;
                        if (!(gridFilter !== null && gridFilter !== void 0 && (_gridFilter$filters = gridFilter.filters) !== null && _gridFilter$filters !== void 0 && _gridFilter$filters.length)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.UpdateTotalRowsFromServer()];
                    case 2:
                        preparedRowTotal = _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/, { rows: preparedRows, totalRow: preparedRowTotal }];
                }
            });
        }); };
        _this.bodyParameters = props.bodyParameters;
        _this.cancelMode = !hasOwnProperty(props, "cancelMode") || props.cancelMode;
        return _this;
    }
    return DataGrid;
}(VertexGrid));
export { DataGrid };
DataGrid.prepareRow = function (row) {
    var _row$Data;
    var data = { dataItem: null, _computed: {} };
    data["@rid"] = row['Value'];
    data.dataItem = (_row$Data = row.Data) === null || _row$Data === void 0 ? void 0 : _row$Data[0];
    row.ValuesTotal.forEach(function (v) {
        // if (ind?.field)
        //     data[ind.field] = v.Value;
        data._computed[GetHashCode(v.IndicateurSignature)] = { Value: v.Value, Formated: v.Formated };
    });
    return data;
};
